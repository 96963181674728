<template>
  <aside class="sns-share">
    <h2 class="title">
      <slot>{{ $t('top046') }}</slot>
    </h2>
    <ul class="link-list">
      <li class="list-item">
        <a
          @click.prevent="shareOnFacebook"
          target="_blank"
          rel="noopener"
        >
          <img
            src="@images/sns-share/icon_fb.png"
            width="90"
            height="90"
            alt="Share on Facebook"
          >
        </a>
      </li>
      <li class="list-item">
        <a
          @click.prevent="shareOnTwitter"
          target="_blank"
          rel="noopener"
        >
          <img
            src="@images/sns-share/icon_tw.png"
            width="90"
            height="90"
            alt="Share on Twitter"
          >
        </a>
      </li>
      <li class="list-item" v-if="isLineShow">
        <a
          @click.prevent="shareOnLine"
          target="_blank"
          rel="noopener"
        >
          <img
            src="@images/sns-share/icon_line.png"
            width="90"
            height="90"
            alt="Share on Line"
          >
        </a>
      </li>
    </ul>
  </aside>
</template>

<i18n lang="yaml" src="@/locales/common.yaml"></i18n>
<i18n lang="yaml" src="@/locales/top.yaml"></i18n>

<script>
export default {
  name: 'SnsShare',

  props: {
    shareText: {
      type: String,
      default: null
    },
    replaceText: {
      type: Object,
      default: null
    }
  },

  computed: {
    isLineShow () {
      return this.$route.params.lang === 'ja-jp'
    },

    gaActionPostfix () {
      let action = ''
      switch (this.$route.name) {
        case 'Hero':
          action = '_cheer'
          break
        case 'Voted':
          action = '_voted'
          break
        case 'Result':
          action = '_result'
          break
        case 'ResultList':
          action = '_result'
          break
      }
      return action
    }
  },

  methods: {
    encodedUrl () {
      const url = this.replaceText ? this.replaceText.url : location.href
      return encodeURIComponent(url)
    },

    convertSpecialCharas (str) {
      return str
        .replace(/<br>/g, '\n')
        .replace(/&amp;/g, '&')
        .replace(/&#039;/g, '\'')
    },

    shareOnFacebook () {
      window.open(`https://www.facebook.com/sharer.php?u=${this.encodedUrl()}`, '_blank')
    },

    shareOnTwitter () {
      let shareText = this.shareText ?
        this.$t(this.shareText, this.replaceText) : 
        this.$t('common01', { url: location.href })
      shareText = this.convertSpecialCharas(shareText)
      window.open(`http://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`, '_blank')
    },

    shareOnLine () {
      let shareText = this.shareText ?
        this.$t(this.shareText, this.replaceText) :
        this.$t('common01', { url: location.href })
      shareText = this.convertSpecialCharas(shareText)
      window.open(`http://line.me/R/msg/text/?${encodeURIComponent(shareText)}`, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.sns-share {
  > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82px;
    color: $color-sub;
    font-size: $fs-middle;
    letter-spacing: $ls-base;
  }
  > .link-list {
  }
}
  .link-list {
    background: url(#{$img-path}sns-share/bg.png) repeat-x;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 111px;    

    > .list-item {
      cursor: pointer;
    }
    > .list-item + .list-item {
      margin-left: 40px;
    }
  }
</style>