import { render, staticRenderFns } from "./sns-share.vue?vue&type=template&id=0faef209&scoped=true&"
import script from "./sns-share.vue?vue&type=script&lang=js&"
export * from "./sns-share.vue?vue&type=script&lang=js&"
import style0 from "./sns-share.vue?vue&type=style&index=0&id=0faef209&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0faef209",
  null
  
)

/* custom blocks */
import block0 from "@/locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fcomponents%2Fglobal%2Fsns-share.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/top.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fcomponents%2Fglobal%2Fsns-share.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports